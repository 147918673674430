/* eslint-disable */
import { Route, Switch, useParams } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';


import { AppRoute } from '../config/route-config';
import PayfonePrefill from '../components/PayfonePrefill';
import BasicInfo from '../components/BasicInfo';
import EmployerInfo from '../components/EmployerInfo';
import ResidenceInfo from '../components/ResidenceInfo';
import ReferenceInfo from '../components/ReferenceInfo';
import ReviewInfo from '../components/ReviewInfo';
import { ApprovalPage } from '../components/ApprovalPage';
import IdentityVerify from '../components/IdentifyVerify';
import UserInfo from '../components/UserInfo';
import DofStartingPage from '../components/DofStartingPage';

// Customver Verification Optimization Component import starts

import { DOFSubmitted } from '../components/CustomerVerification/VerificationComponents/DOFSubmitted';
import { IdentityTab } from '../components/CustomerVerification/VerificationComponents/IdentityTab';
import { ResidenceTab } from '../components/CustomerVerification/VerificationComponents/ResidenceTab';
import { IncomeTab } from '../components/CustomerVerification/VerificationComponents/Income';
import ReferenceTab from '../components/CustomerVerification/VerificationComponents/ReferenceTab';
import { ReviewInformation } from '../components/CustomerVerification/VerificationComponents/ReviewInformation';
import { WelcomePage } from '../components/CustomerVerification/VerificationComponents/WelcomePage';
import { OTPAuthenticationTab } from '../components/CustomerVerification/VerificationComponents/OTPAuthentication';
import DOFStartingPage from '../components/CustomerVerification/VerificationComponents/DOFStartingPage';
import { getConfigDetails, validateAuthKey, GetApproval, GetDropOptions } from '../api/user';
import { DigitalOrderFormContext } from '../components/CustomerVerification/Context/DigitialOrderFormContext';
import { ReferenceTabContext } from '../components/CustomerVerification/Context/ReferenceDetailsContext';

import ScreenLoader from '../components/CustomerVerification/Common/ScreenLoader';
import { checkRACPadCustomer, dropDownOptionsBinding, MakeFeatureFlagObjFn, setDocumentState } from '../components/CustomerVerification/Common/HelperFunctions';
import { OTPVerified } from '../components/CustomerVerification/VerificationComponents/OtpVerified';

export const routesTestId = 'routesTestId';


export const Routes = () => {
  const history = useHistory();
  const queryParams = new URLSearchParams(window.location.search);
  const storeCode = queryParams.get("StoreCode");
  const authKey = queryParams.get("AuthKey")

  const { decisionEngineDetails, setDecisionEngineDetails, setCustomerId, setRacPadCustomerDetail, setIncomeDoc, setResidenceDoc, set, setIdentityFrontDoc } = useContext(DigitalOrderFormContext)

  const { setRelationshipOptions, relationshipOptions } = useContext(ReferenceTabContext)


  const [screenLoader, setScreenLoader] = useState(false)
  const params = new URLSearchParams(window.location.search);
  const EncodeAuthKey = params.get('AuthKey');
  const EncodedStorecode = params.get('StoreCode') || '';
  const EncodeUserId = params.get('userId') || '';
  const EncodeClientId: any = params.get('clientId')
  const EncodedeId = params.get('deId')
  const EncodeSalesLeadId = params.get('salesLeadId')


  let dofAuthValues: any = {};
  // To retrieve the object from session storage
  let dofAuthSessionValue: any = window.sessionStorage.getItem("dofAuthValues");
  let sessionStorageValues: any = JSON.parse(dofAuthSessionValue);

  useEffect(() => {
    console.log("storedValues", sessionStorageValues);

    // Ensure sessionStorageValues is valid before checking Object.keys
    if (!sessionStorageValues || Object.keys(sessionStorageValues).length < 1) {
      if (EncodedStorecode) {
        dofAuthValues.encodeStoreNumber = EncodedStorecode;
        dofAuthValues.storeNumber = atob(EncodedStorecode);
        window.sessionStorage.setItem("storeNumber", atob(EncodedStorecode));
      }
      if (EncodeUserId) {
        dofAuthValues.encodeUserId = atob(EncodeUserId);
        window.sessionStorage.setItem("encodeUserId", atob(EncodeUserId));
      }
      if (EncodeAuthKey) {
        dofAuthValues.authKey = EncodeAuthKey;
        window.sessionStorage.setItem("authKey", atob(EncodeUserId));
      }
      if (EncodeClientId) {
        dofAuthValues.clientId = EncodeClientId;
      }
      if (EncodedeId) {
        dofAuthValues.approvalId = atob(EncodedeId);
      }
      if (EncodeSalesLeadId) {
        dofAuthValues.salesLeadId = atob(EncodeSalesLeadId);
      }
      window.sessionStorage.setItem("dofAuthValues", JSON.stringify(dofAuthValues));
    }

    // Store the object in sessionStorage

    // Call the validation function
    validateAuthKeyFn(
      EncodeAuthKey ? EncodeAuthKey : sessionStorageValues?.authKey,
      EncodedStorecode ? EncodedStorecode : sessionStorageValues?.encodeStoreNumber
    );

  }, []);


  // Get Config Details Function
  const getConfigDetailsFn = async () => {

    // To retrieve the object from session storage
    let dofAuthSessionValue: any = window.sessionStorage.getItem("dofAuthValues")
    let sessionStorageValues: any = JSON.parse(dofAuthSessionValue);
    const StoreId = sessionStorageValues?.storeNumber
    const featureFlagReq = {
      storeNumbers: [sessionStorageValues?.storeNumber],
      paramKeyNames: ['DOFCustomerVerification', 'IntellicheckDocumentSides'],
    };
    let FeatureFlagResponse: any = '';



    if (sessionStorageValues?.dofFeatureFlag == '1') {
      await getCustomerDetails()
    }
    else if (sessionStorageValues?.dofFeatureFlag == '0') {
      setScreenLoader(false)
      authKey
        ? history.push(
          `/dof?AuthKey=${authKey}&userId=${EncodeUserId}&StoreCode=${storeCode}`
        )
        : history.push(`/dof?StoreCode=${storeCode}&userId=${EncodeUserId}`); return
    }
    else if (StoreId && StoreId != null && StoreId != undefined && StoreId != '' && StoreId != 'null' && StoreId != 'undefined') {
      setScreenLoader(true)
      FeatureFlagResponse = await getConfigDetails(
        featureFlagReq, sessionStorageValues?.authKey ? false : sessionStorageValues?.storeNumber,
        sessionStorageValues?.authKey ? sessionStorageValues?.authKey : sessionStorageValues?.storeNumber
      );
      setScreenLoader(false)
      if (FeatureFlagResponse?.status == 200) {
        const featureFlagDetails = JSON.stringify(FeatureFlagResponse?.data?.storeProfileResponse?.configDetails[0]?.configDetails)
        window.sessionStorage.setItem('featureFlagDetails', featureFlagDetails);

        if (FeatureFlagResponse?.data?.storeProfileResponse?.configDetails[0]?.configDetails) {
          const FeatureFlagObj = MakeFeatureFlagObjFn(
            FeatureFlagResponse?.data?.storeProfileResponse?.configDetails[0]?.configDetails
          )
          if (FeatureFlagObj?.DOFCustomerVerification == 1) {
            dofAuthValues.dofFeatureFlag = FeatureFlagObj?.DOFCustomerVerification
            window.sessionStorage.setItem("dofAuthValues", JSON.stringify(dofAuthValues));
            await getCustomerDetails()
          }
          else {
            dofAuthValues.dofFeatureFlag = '0'
            window.sessionStorage.setItem("dofAuthValues", JSON.stringify(dofAuthValues));

            setScreenLoader(false)

            authKey
              ? history.push(
                `/dof?AuthKey=${authKey}&userId=${EncodeUserId}&StoreCode=${storeCode}`
              )
              : history.push(`/dof?StoreCode=${storeCode}&userId=${EncodeUserId}`);
          }

        }
        else {
          dofAuthValues.dofFeatureFlag = '0'
          window.sessionStorage.setItem("dofAuthValues", JSON.stringify(dofAuthValues));

          setScreenLoader(false)

          authKey
            ? history.push(
              `/dof?AuthKey=${authKey}&userId=${EncodeUserId}&StoreCode=${storeCode}`
            )
            : history.push(`/dof?StoreCode=${storeCode}&userId=${EncodeUserId}`);
        }
      }
      else {
        dofAuthValues.dofFeatureFlag = '0'
        window.sessionStorage.setItem("dofAuthValues", JSON.stringify(dofAuthValues));
        setScreenLoader(false)
        authKey
          ? history.push(
            `/dof?AuthKey=${authKey}&userId=${EncodeUserId}&StoreCode=${storeCode}`
          )
          : history.push(`/dof?StoreCode=${storeCode}&userId=${EncodeUserId}`);
      }
    }

  };

  // Function for Validating the authKey
  const validateAuthKeyFn = async (EncodeAuthKey?: any, EncodedStorecode?: any) => {
    setScreenLoader(true)
    if (EncodeAuthKey) {
      console.log('flow of auth');
      let ResponseValidateAuth: any = await validateAuthKey(EncodeAuthKey, false);
      ResponseValidateAuth = ResponseValidateAuth?.data
      console.log(ResponseValidateAuth, 'response');
      if (
        ResponseValidateAuth &&
        ResponseValidateAuth?.valid === true
      ) {
        getConfigDetailsFn()
      }
      else {
        window.parent.postMessage(
          {
            status: 504,
            message: "Authentication timeout. Please try again.",
            verificationProcessCompleted: false
          },
          "*"
        );
        history.push(`/otp/auth/${sessionStorageValues?.approvalId}?session=timeout`)
      }
    } else if (EncodedStorecode) {
      console.log('storeCode flow');
      const DecodeStoreCode = new Buffer(EncodedStorecode, 'base64');
      let DecodedNum = DecodeStoreCode.toString('ascii');
      const ValidateStore = await validateAuthKey(DecodedNum, true);
      console.log(ValidateStore, 'validates');
      if (ValidateStore) {
        getConfigDetailsFn()
      }
      else {
        window.parent.postMessage(
          {
            status: 504,
            message: "Authentication timeout. Please try again.",
            verificationProcessCompleted: false
          },
          "*"
        );
        history.push(`/otp/auth/${sessionStorageValues?.approvalId}?session=timeout`)
      }
    }
    setScreenLoader(false)
  };


  //  Function for getting the customer Details 

  const getCustomerDetails = async () => {
    let dofAuthSessionValue: any = window.sessionStorage.getItem("dofAuthValues");
    let sessionStorageValues: any = JSON.parse(dofAuthSessionValue);
    setScreenLoader(true)

    const request: any = {
      "decisionId": sessionStorageValues?.approvalId
    }
    if (relationshipOptions?.length < 1 && sessionStorageValues?.approvalId) {
      dropDownOptionsBinding(setRelationshipOptions)
    }

    if (!EncodedeId && !sessionStorageValues?.approvalId) {
      if (EncodedStorecode) {
        setScreenLoader(false);
        authKey
          ? history.push(
            `/dof?AuthKey=${authKey}&userId=${EncodeUserId}&StoreCode=${storeCode}`
          )
          : history.push(`/dof?StoreCode=${storeCode}&userId=${EncodeUserId}`);
      }
    }
    else {
      // Calling the Get Approval Service
      if (request?.decisionId) {
        const getApprovalServiceResponse: any = await GetApproval(request, sessionStorageValues?.authKey ? false : sessionStorageValues?.storeNumber,
          sessionStorageValues?.authKey ? sessionStorageValues?.authKey : sessionStorageValues?.storeNumber);

        console.log('Get Approval Service Response', getApprovalServiceResponse);
        if (getApprovalServiceResponse && getApprovalServiceResponse?.globalCustomerId) {
          setDecisionEngineDetails(getApprovalServiceResponse)
          setScreenLoader(false);
          checkRACPadCustomer(getApprovalServiceResponse?.globalCustomerId, setRacPadCustomerDetail, setCustomerId);
          setDocumentState(request?.decisionId, setIdentityFrontDoc, setResidenceDoc, setIncomeDoc);
          const clientId: any = sessionStorageValues?.clientId
          if (atob(clientId) == 'EC' && (storeCode || authKey)) {
            history.push(`/dof/verification/${sessionStorageValues?.approvalId}`)
          }
          else if (storeCode || authKey) {
            history.push(`/welcome/${sessionStorageValues?.approvalId}`)
          }
        }
        else {
          setScreenLoader(false)
          history.push(`/welcome/${sessionStorageValues?.approvalId}`)
        }
      }
      else {

        authKey
          ? history.push(
            `/dof?AuthKey=${authKey}&userId=${EncodeUserId}&StoreCode=${storeCode}`
          )
          : history.push(`/dof?StoreCode=${storeCode}&userId=${EncodeUserId}`);

      }
    }

  }

  return (

    < div data-testid={routesTestId}>

      {screenLoader ? <ScreenLoader /> : null}
      <Switch>
        <Route
          exact
          path={AppRoute.startingPage}
          key="/"
          component={DofStartingPage}
        />
        <Route
          exact
          path={AppRoute.payfoneprefill}
          key="/payfoneprefill"
          component={PayfonePrefill}
        />
        <Route
          exact
          path={AppRoute.basicInfo}
          key="/basicinfo"
          component={BasicInfo}
        />
        <Route
          exact
          path={AppRoute.employerInfo}
          key="/employerinfo"
          component={EmployerInfo}
        />
        <Route
          exact
          path={AppRoute.residenceInfo}
          key="/residenceinfo"
          component={ResidenceInfo}
        />
        <Route
          exact
          path={AppRoute.referenceInfo}
          key="/referenceinfo"
          component={ReferenceInfo}
        />
        <Route
          exact
          path={AppRoute.reviewInfo}
          key="/reviewInfo"
          component={ReviewInfo}
        />
        <Route
          exact
          path={AppRoute.ApprovalPage}
          key="/ApprovalPage"
          component={ApprovalPage}
        />
        <Route
          exact
          path={AppRoute.identifyVerify}
          key="/IdentityVerify"
          component={IdentityVerify}
        />
        <Route
          exact
          path={AppRoute.userInfo}
          key="/userInfo"
          component={UserInfo}
        />

        <Route
          exact
          path={AppRoute.dofStartPage}
          key="/dof/verification/:approvalId"
          component={DOFStartingPage}
        />
        <Route
          path={AppRoute.welcomepage}
          key="/welcome/:approvalId"
          component={WelcomePage}
        />
        <Route
          path={AppRoute.indentitytab}
          key="/identity/:customerId/:approvalId"
          component={IdentityTab}
        />
        <Route
          path={AppRoute.residenceTab}
          key="/residence/:customerId/:approvalId"
          component={ResidenceTab}
        />
        <Route
          path={AppRoute.incomeTab}
          key="/income/:customerId/:approvalId"
          component={IncomeTab}
        />
        <Route
          path={AppRoute.reference}
          key="/reference/:customerId/:approvalId"
          component={ReferenceTab}
        />
        <Route
          path={AppRoute.reviewInformation}
          key="/reviewInformation/:customerId?/:approvalId?"
          component={ReviewInformation}
        />
        <Route
          path={AppRoute.dofSubmitted}
          key="/dof/submission/:customerId/:approvalId"
          component={DOFSubmitted}
        />
        <Route
          path={AppRoute.dofAuthentication}
          key="/otp/auth/:approvalId"
          component={OTPAuthenticationTab}
        />
        <Route
          path={AppRoute.otpVerified}
          key="/otp/verified/:approvalId"
          component={OTPVerified}
        />

      </Switch>
    </div >
  )


}