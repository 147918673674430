/* eslint-disable prettier/prettier */
import React, { createContext, useState } from 'react';
export const ResidenceTabContext = createContext<any>('');
export function ResidenceTabContextProvider(props: any) {
    const [landlordResidence, setLandlordResidence] = useState({
        landLordName: '',
        timeAtCurrentAddress: '',
        landLordPhoneNumber: '',
        formatedMobileNumber: '',
        landlordReferenceId: ''

    })
    const [residenceType, setResidenceType] = useState<string>('RENT');
    const [manualEntryEnabled, setManualEntryEnabled] = useState(false);
    const [fileSize, setFileSize] = useState<string>('');
    const [fileType, setFileType] = useState<string>('');
    return (
        <div>
            <ResidenceTabContext.Provider
                value={{
                    landlordResidence, setLandlordResidence,
                    manualEntryEnabled, setManualEntryEnabled,
                    residenceType, setResidenceType,
                    fileType, setFileType,
                    fileSize, setFileSize
                }}
            >
                {props.children}
            </ResidenceTabContext.Provider>
        </div>
    );
}
