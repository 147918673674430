/* eslint-disable prettier/prettier */
import React, { createContext, useState } from 'react';
import { CustomerInfo, IntellicheckCustomerDetails } from './../../../interfaces/CustomerVerificationModel/DigitalOrderFormModel';
import { CUSTOMER_INFO_TEMPLATE, INTELLICHECK_DETAILS_TEMPLATE } from './../../../constants/constants';
export const DigitalOrderFormContext = createContext<any>('');
export function DigitalOrderFormContextProvider(props: any) {
    const [customerDetails, setCustomerDetails] = useState<CustomerInfo>(CUSTOMER_INFO_TEMPLATE);
    const [decisionEngineDetails, setDecisionEngineDetails] = useState<any>('');
    const [intellicheckVerfDetails, setIntellicheckVerfDetails] = useState<IntellicheckCustomerDetails>(INTELLICHECK_DETAILS_TEMPLATE);
    const [businessFlow, setBusinessFlow] = useState<string>('DOF');
    const [racpadCustomerDetail, setRacPadCustomerDetail] = useState<any>('');
    const [customerId, setCustomerId] = useState<string>('');
    const [identityFrontDoc, setIdentityFrontDoc] = useState<string>('');
    const [identitySelfieDoc, setIdentitySelfieDoc] = useState<string>('');
    const [incomeDoc, setIncomeDoc] = useState<string>('');
    const [residenceDoc, setResidenceDoc] = useState<string>('');
    const [showIdentityDetails, setShowIdentityDetails] = useState<boolean>(false);
    const [showIncomeDetails, setShowIncomeDetails] = useState<boolean>(false);
    const [showReferenceDetails, setShowReferenceDetails] = useState<boolean>(false);
    const [showResidenceDetails, setShowResidenceDetails] = useState<boolean>(false);
    const [showIncomeVerfDocument, setShowIncomeVerfDocument] = useState<boolean>(false);
    const [showResidenceVerfDocument, setShowResidenceVerfDocument] = useState<boolean>(false);
    const [fileSize, setFileSize] = useState<string>('');
    const [fileType, setFileType] = useState<string>('');
    const [existingFlow, setexistingFlow] = useState<boolean>(false);
    const [dynamicHeader, setDynamicHeader] = useState<any>([]);

    return (
        <div>
            <DigitalOrderFormContext.Provider
                value={{
                    customerDetails, setCustomerDetails,
                    decisionEngineDetails, setDecisionEngineDetails,
                    intellicheckVerfDetails, setIntellicheckVerfDetails,
                    businessFlow, setBusinessFlow,
                    racpadCustomerDetail, setRacPadCustomerDetail,
                    customerId, setCustomerId,
                    identityFrontDoc, setIdentityFrontDoc,
                    identitySelfieDoc, setIdentitySelfieDoc,
                    incomeDoc, setIncomeDoc,
                    residenceDoc, setResidenceDoc,
                    showResidenceDetails, setShowResidenceDetails,
                    showIdentityDetails, setShowIdentityDetails,
                    showIncomeDetails, setShowIncomeDetails,
                    showReferenceDetails, setShowReferenceDetails,
                    showIncomeVerfDocument, setShowIncomeVerfDocument,
                    showResidenceVerfDocument, setShowResidenceVerfDocument,
                    fileSize, setFileSize,
                    fileType, setFileType,
                    existingFlow, setexistingFlow,
                    dynamicHeader, setDynamicHeader
                }}
            >
                {props.children}
            </DigitalOrderFormContext.Provider>
        </div>
    );
}
