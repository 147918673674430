/*eslint-disable */
import { client, clientCall } from './client';
import { appConfig } from '../config/app-config';
import { CancelToken } from 'axios';
import { ProductPermissions } from '../constants/constants';
import { User } from '../types/types';
import { GetIntellicheckDetailsPayload } from '../interfaces/CustomerVerificationModel/DigitalOrderFormModel';



export const FindCustomer = async (payload: any, store?: any, value?: any) => {
  if (store) {
    return await clientCall(
      `customer/find?store=${value}`,
      { method: 'POST', body: payload },
      appConfig.apiUrls.micro,
      true
    );
  }
  else {
    return await clientCall(
      `customer/find?authkey=${value}`,
      { method: 'POST', body: payload },
      appConfig.apiUrls.micro,
      true
    );
  }
};
export const GetApproval = async (payload: any, store?: any, value?: any) => {
  if (store) {
    return await clientCall(
      `customer/find?store=${value}`,
      { method: 'POST', body: payload },
      appConfig.apiUrls.micro,
      true
    );
  }
  else {
    return await clientCall(
      `customer/find?authkey=${value}`,
      { method: 'POST', body: payload },
      appConfig.apiUrls.micro,
      true
    );
  }
};

export const GetCustomerDetails = async (payload: any, store?: any, value?: any) => {
  if (store) {
    return await client(
      `customer/find?store=${value}`,
      { method: 'POST', body: payload },
      appConfig.apiUrls.micro,
      true
    );
  }
  else {
    return await client(
      `customer/find?authkey=${value}`,
      { method: 'POST', body: payload },
      appConfig.apiUrls.micro,
      true
    );
  }
};

export const GetDropOptions = async (payload: any, store?: any, value?: any) => {
  if (store) {
    return await clientCall(
      `drop/options?store=${value}`,
      { method: 'POST', body: payload },
      appConfig.apiUrls.micro,
      true
    );
  }
  else {
    return await clientCall(
      `drop/options?authkey=${value}`,
      { method: 'POST', body: payload },
      appConfig.apiUrls.micro,
      true
    );
  }

};

export const RunDecisionEngine = async (payload: any, store?: any, value?: any) => {
  if (store) {
    return await clientCall(
      `approval?store=${value}`,
      { method: 'POST', body: payload },
      appConfig.apiUrls.micro,
      true
    );
  }
  else {
    return await clientCall(
      `approval?authkey=${value}`,
      { method: 'POST', body: payload },
      appConfig.apiUrls.micro,
      true
    );
  }

};

export const PostNoDeReason = async (payload: any, store?: any, value?: any) => {
  if (store) {
    return await clientCall(
      `nodereason/update?store=${value}`,
      { method: 'POST', body: payload },
      appConfig.apiUrls.micro,
      true
    );
  }
  else {
    return await clientCall(
      `nodereason/update?authkey=${value}`,
      { method: 'POST', body: payload },
      appConfig.apiUrls.micro,
      true
    );
  }

};

export async function CreateCustomer(payload: any, store?: any, value?: any) {
  if (store) {
    return await clientCall(
      `customer/create?store=${value}`,
      { method: 'POST', body: payload },
      appConfig.apiUrls.micro,
      true
    );
  }
  else {
    return await clientCall(
      `customer/create?authkey=${value}`,
      { method: 'POST', body: payload },
      appConfig.apiUrls.micro,
      true
    );
  }

}

export async function UpdateCustomerInfo(payload: any, store?: any, value?: any) {
  if (store) {
    return await clientCall(
      `customer/update?store=${value}`,
      { method: 'POST', body: payload },
      appConfig.apiUrls.micro,
      true
    );
  }
  else {
    return await clientCall(
      `customer/update?authkey=${value}`,
      { method: 'POST', body: payload },
      appConfig.apiUrls.micro,
      true
    );
  }

}
export async function PublishDOFNotification(payload: any, store?: any, value?: any) {
  if (store) {
    return await clientCall(
      `customer/update?store=${value}`,
      { method: 'POST', body: payload },
      appConfig.apiUrls.micro,
      true
    );
  }
  else {
    return await clientCall(
      `customer/update?authkey=${value}`,
      { method: 'POST', body: payload },
      appConfig.apiUrls.micro,
      true
    );
  }

}
export async function StoreActionItemInsertion(payload: any, store?: any, value?: any) {
  if (store) {
    return await clientCall(
      `customer/update?store=${value}`,
      { method: 'POST', body: payload },
      appConfig.apiUrls.micro,
      true
    );
  }
  else {
    return await clientCall(
      `customer/update?authkey=${value}`,
      { method: 'POST', body: payload },
      appConfig.apiUrls.micro,
      true
    );
  }

}

export const GetPafoneDetails = async (
  phoneNumber: string,
  last4: string,
  store?: any,
  value?: any
) => {

  if (store) {
    return await clientCall(
      `payfone/details?store=${value}`,
      { method: 'POST', body: { phoneNumber, last4 } },
      appConfig.apiUrls.micro,
      true
    );
  }
  else {
    return await clientCall(
      `payfone/details?authkey=${value}`,
      { method: 'POST', body: { phoneNumber, last4 } },
      appConfig.apiUrls.micro,
      true
    );
  }

};
export const validateAuthKey = async (payload: any, store: boolean) => {
  try {
    const body: any = {};
    if (store) {
      body.storeNumber = payload;
      return await clientCall(
        `accesslink/validate?store=${payload}`,
        { method: 'POST', payload: body },
        appConfig.apiUrls.micro
      );
    } else {
      body.authKey = payload;
      return await clientCall(
        `accesslink/validate?authkey=${payload}`,
        { method: 'POST', payload: body },
        appConfig.apiUrls.micro
      );
    }


  } catch (e: any) {
    return e;
  }
};

export const sendOTP = async (payload: any, store: any, value: any) => {
  try {
    const body: any = {};
    if (store) {
      body.phoneNumber = payload;
      body.sendOtp = true;
      return await clientCall(
        `otp/send?store=${value}`,
        { method: 'POST', payload: body },
        appConfig.apiUrls.micro
      );
    }
    else {

      body.phoneNumber = payload;

      body.sendOtp = true;
      return await clientCall(
        `otp/send?authkey=${value}`,
        { method: 'POST', payload: body },
        appConfig.apiUrls.micro
      );
    }

  } catch (e: any) {
    return e;
  }
};
export const ValidateOTP = async (payload: any, store: any, value: any) => {
  try {
    if (store) {
      return await clientCall(
        `otp/send?store=${value}`,
        { method: 'POST', payload: payload },
        appConfig.apiUrls.micro
      );
    }
    else {


      return await clientCall(
        `otp/send?authkey=${value}`,
        { method: 'POST', payload: payload },
        appConfig.apiUrls.micro
      );
    }

  } catch (e: any) {
    return e;
  }
};

export const updateAccessLink = async (payload: any, store?: any, value?: any) => {
  try {
    const body: any = {};
    if (store) {
      return await clientCall(
        `accesslink/update?store=${value}`,
        { method: 'POST', payload: payload },
        appConfig.apiUrls.micro
      );
    }
    else {
      return await clientCall(
        `accesslink/update?authkey=${value}`,
        { method: 'POST', payload: payload },
        appConfig.apiUrls.micro
      );
    }


  } catch (e: any) {
    return e;
  }
};



export const getCurrentUser = (): Promise<User> =>
  clientCall(
    'users/current',
    { method: 'GET', body: {} },
    appConfig.apiUrls.container,
    false
  );

export const getUserPreferences = (productType: string) =>
  clientCall(
    `users/current/preferences/${productType}`,
    { method: 'GET' },
    appConfig.apiUrls.container,
    false
  );

export const getUserPermissions = (
  productType: string
): Promise<ProductPermissions[]> =>
  clientCall(
    `users/current/permissions/${productType}`,
    { method: 'GET' },
    appConfig.apiUrls.container,
    false
  );

export const setUserPreferences = (
  productType: string,
  payload: Record<string, any>,
  cancelToken?: CancelToken
) =>
  clientCall(
    `users/current/preferences/${productType}`,
    {
      method: 'PATCH',
      body: [payload],
      cancelToken,
    },
    appConfig.apiUrls.container,
    false
  );

export const getUserStores = () => {
  clientCall(
    'users/current/stores?q=permissions[:includes]=RACPAD_DAP',
    { method: 'GET' },
    appConfig.apiUrls.container,
    false
  );
};

export const sendIntellicheckVerificationLink = async (payload: any) => {
  // PS_14: Calling clientCall function
  return client(
    'send/intellicheck/verification-link?store=02202',
    {
      method: 'POST',
      payload,
    },
    appConfig.apiUrls.micro,
    false
  );
};

export const getIntellicheckVerificationDetails = async (payload: GetIntellicheckDetailsPayload) => {
  // PS_14: Calling clientCall function
  return client(
    `get/intellicheck/verification-details?store=02202&gcid=${payload.customerApprovalId}&businessUnit=${payload.businessUnit}`,
    {
      method: 'GET',
      payload,
    },
    appConfig.apiUrls.micro,
    false
  );
};
export const getConfigDetails = async (payload: any, store?: any, value?: any) => {
  try {
    if (store) {
      return await client(
        `get/config?store=${value}`,
        { method: 'POST', payload: payload },
        appConfig.apiUrls.micro
      );
    }
    else {
      return await client(
        `get/config?authkey=${value}`,
        { method: 'POST', payload: payload },
        appConfig.apiUrls.micro
      );
    }


  } catch (e: any) {
    return e;
  }
};
export const generateDOFAccessLink = async (payload: any, store?: any, value?: any) => {
  try {
    if (store) {
      return await client(
        `generate/dof-link?store=${value}`,
        { method: 'POST', payload: payload },
        appConfig.apiUrls.micro
      );
    }
    else {
      return await client(
        `generate/dof-link?authkey=${value}`,
        { method: 'POST', payload: payload },
        appConfig.apiUrls.micro
      );
    }


  } catch (e: any) {
    return e;
  }
};

export const sendOneTimePasscode = async (payload: any, store: any, value: any) => {
  try {
    const body: any = {};
    if (store) {
      body.phoneNumber = payload;
      body.sendOtp = true;
      return await client(
        `otp/send?store=${value}`,
        { method: 'POST', payload: body },
        appConfig.apiUrls.micro
      );
    }
    else {
      body.phoneNumber = payload;
      body.sendOtp = true;
      return await client(
        `otp/send?authkey=${value}`,
        { method: 'POST', payload: body },
        appConfig.apiUrls.micro
      );
    }

  } catch (e: any) {
    return e;
  }
};

export const validateOneTimePassword = async (payload: any, store: any, value: any) => {
  try {
    if (store) {
      return await client(
        `otp/send?store=${value}`,
        { method: 'POST', payload: payload },
        appConfig.apiUrls.micro
      );
    }
    else {
      return await client(
        `otp/send?authkey=${value}`,
        { method: 'POST', payload: payload },
        appConfig.apiUrls.micro
      );
    }

  } catch (e: any) {
    return e;
  }
};

export const uploadVerificationDocuments = async (payload: any, store: any, value: any) => {
  try {
    if (store) {
      return await client(
        `verification/document/upload?store=${value}`,
        { method: 'POST', payload: payload },
        appConfig.apiUrls.micro
      );
    }
    else {
      return await client(
        `verification/document/upload?authkey=${value}`,
        { method: 'POST', payload: payload },
        appConfig.apiUrls.micro
      );
    }

  } catch (e: any) {
    return e;
  }
};
export const getVerificationDocuments = async (payload: any, store: any, value: any) => {
  try {
    if (store) {
      return await client(
        `verification/document/?store=${value}`,
        { method: 'POST', payload: payload },
        appConfig.apiUrls.micro
      );
    }
    else {
      return await client(
        `verification/document/?authkey=${value}`,
        { method: 'POST', payload: payload },
        appConfig.apiUrls.micro
      );
    }

  } catch (e: any) {
    return e;
  }
};
