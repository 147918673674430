/* eslint-disable  */
import React from 'react';
import { Grid, Typography } from "@rentacenter/racstrap";
import { EMPTY_STRING } from "../../../constants/constants";
import { GovernmentIdType, IntellicheckGovtIDType } from "../../../constants/enums";
import { CustomerInfo } from "../../../interfaces/CustomerVerificationModel/DigitalOrderFormModel";
import { VerificationStyles } from '../../../styles/verificationStyles';
import { GetCustomerDetails, GetDropOptions, getVerificationDocuments, PublishDOFNotification, StoreActionItemInsertion, UpdateCustomerInfo, uploadVerificationDocuments } from '../../../api/user';
import jsPDF from 'jspdf';
import { string } from 'yup';

// Helper function to safely access nested properties and return a default value if the property is not found.
export const getValue = (data: any, path: string, defaultValue: string = EMPTY_STRING) => {
  // Split the path into an array of keys
  const pathParts = path.split('.');

  // Use reduce to traverse the path and access the nested value
  const value = pathParts.reduce((acc, part) => acc?.[part], data);

  // Return the found value or the default value if it's undefined or null
  return value ?? defaultValue;
};

// Helper function to safely access an array at a given path, returning an empty array if not found.
export const getArrayValue = (data: any, path: string) => {
  // Split the path into an array of keys
  const pathParts = path.split('.');

  // Traverse the object along the path to get the value
  const value = pathParts.reduce((acc, part) => acc?.[part], data);

  // Return the value if it's an array, otherwise return an empty array
  return Array.isArray(value) ? value : [];
};

// Function to determine the ID document type based on government ID type
export const getIdDocumentType = (customerDetails: CustomerInfo) => {
  const governmentIdType = customerDetails?.taxInfo?.governmentIdType;

  switch (governmentIdType) {
    case GovernmentIdType.DL:
    case GovernmentIdType.UDL:
      return IntellicheckGovtIDType.na_dl;
    case GovernmentIdType.PASS:
    case GovernmentIdType.UPASS:
      return IntellicheckGovtIDType.passport;
    default:
      return IntellicheckGovtIDType.other;
  }
};

/* 
  This function is used to dynamically bind headings based on the Tab. 
  Just provide the tab name, and it will return the heading.
*/
export const getTabsHeading = (tabName: string) => {
  const classes: any = VerificationStyles();
  return (
    <Grid className={classes.headingWidth}>
      <Typography className={`${classes.heading}`}>{`Please give us some details about your ${tabName}`}</Typography>
    </Grid>
  )
}

export const getNoteText = () => {
  const classes: any = VerificationStyles();
  return (
    <Grid className={`${classes.w85p}`}>
      <Typography className={`${classes.font14px}`}><span className={`${classes.boldFont1}`}>NOTE: </span>If you prefer to continue with a store coworker, please call or visit your store.</Typography>
    </Grid>
  )
}
export interface dropdown {

  label: string;

  value: string;

  paydayid?: number;

}





export const buildDropdownList = (optionsList: any): dropdown[] => {

  const options: dropdown[] = [{ value: '', label: 'Select' }];

  const referenceOptions = optionsList.filter((obj: any) => obj.referenceCode != 'REL')
  // eslint-disable-next-line no-console
  console.log("referenceOptions", referenceOptions)

  if (

    referenceOptions !== undefined &&

    referenceOptions !== null &&

    referenceOptions.length > 0

  ) {

    referenceOptions.map((val: any) => {

      options.push({ label: val.description, value: val.referenceCode });

    });

  }



  return options;

};

export const updateCustomerApproval = async (tabName: string, decisionId: string, customerId: string, updateDetails?: any) => {
  // eslint-disable-next-line no-console
  console.log('Tab Name', tabName, updateDetails);

  const payload = {
    customerId: customerId,
    deDecisionId: decisionId,
    isManageApproval: true,
    verifyCode: "1",
    verificationDocuments: [
      {
        verificationStatus: updateDetails ? updateDetails?.status : "PENDREW",
        verified: "N",
        verifyType: tabName,
        modifiedBy: updateDetails ? updateDetails?.modifiedBy : "DOF",
      },
    ],
  };
  console.log('Payload for Update Approval', payload);
  await UpdateCustomerInfo(payload, '00429', '00429')
}

export const uploadDocuments = async (decisionId: string, documents: any) => {
  const payload: any = {
    isVerificationDocument: true,
    customerApprovalId: decisionId,
    documents: documents,
  };

  const uploadDocRes: any = await uploadVerificationDocuments(payload, '02202', '02202');

  // eslint-disable-next-line no-console
  console.log('Upload Documents Res', uploadDocRes)

  if (uploadDocRes?.status == 200) {
    // eslint-disable-next-line no-console
    console.log('Upload document sucess', uploadDocRes?.data)
  } else {
    const documentType = documents[0]?.documentType == 'EMPDOC' ? 'Income' : documents[0]?.documentType == 'IDENTTY' ? 'Intellicheck' : documents[0]?.documentType == 'RESDOC' ? 'Residence' : ''
    // eslint-disable-next-line no-console
    console.log('Something went wrong document upload', uploadDocRes?.data)
    window.parent.postMessage({
      status: 400,
      message: `The ${documentType} Document verification failed due to an technical issue.`,
      verificationProcessCompleted: false
    },
      "*")
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const checkRACPadCustomer = async (gcid: string, setRacPadCustomerDetail: any, setCustomerId: any) => {

  const getCustomerDetailResp: any = await GetCustomerDetails({ GcId: gcid }, '02202', '02202');

  // eslint-disable-next-line no-console
  console.log('Detail Service Response', getCustomerDetailResp, getCustomerDetailResp?.status == 200, getCustomerDetailResp?.data);

  if (getCustomerDetailResp && getCustomerDetailResp?.status == 200 && getCustomerDetailResp?.data) {
    // eslint-disable-next-line no-console
    console.log('Inside the getCustomerDetailResp', getCustomerDetailResp)
    setRacPadCustomerDetail(getCustomerDetailResp.data);
    setCustomerId(getCustomerDetailResp.data?.customerId);
    window.sessionStorage.setItem("customerId", getCustomerDetailResp.data?.customerId)
    return getCustomerDetailResp.data?.customerId
  } else {
    return ''
  }

}

export const convertImageToPDF = async (imageBase64: any) => {
  // eslint-disable-next-line no-console
  console.log(
    "Image Base4",
    imageBase64.replace(/^data:image\/png;base64,/, "")
  );
  const pdf = new jsPDF();

  // Remove the 'data:image/jpeg;base64,' part of the string
  const base64Image = imageBase64.replace(
    /^data:image\/(png|jpeg);base64,/,
    ""
  );

  const img = new Image();
  img.src = `data:image/jpeg;base64,${base64Image}`; // Prefix it again for img.src

  // img.onload = async () => {
  //   // Get the natural width and height of the image
  //   const imgWidth = img.width;
  //   const imgHeight = img.height;

  //   // Get the page size of the PDF (A4 by default)
  //   const pdfWidth = pdf.internal.pageSize.getWidth();
  //   const pdfHeight = pdf.internal.pageSize.getHeight();

  //   // Calculate the scaling factor to fit the image on the page while maintaining the aspect ratio
  //   const scaleFactor = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);

  //   // Calculate the new dimensions to fit within the PDF page size
  //   const scaledWidth = imgWidth * scaleFactor;
  //   const scaledHeight = imgHeight * scaleFactor;

  //   // Add the image to the PDF with the calculated dimensions
  //   pdf.addImage(`data:image/jpeg;base64,${base64Image}`, 'JPEG', 0, 0, scaledWidth, scaledHeight);
  //   const pdfBlob: any = await pdf.output("blob"); // Generate PDF Blob
  //   // eslint-disable-next-line no-console
  //   console.log('PDF Blob', pdfBlob);
  //   return pdfBlob;
  // };

  // img.onerror = (err) => {
  //   // eslint-disable-next-line no-console
  //   console.error("Error loading image:", err);
  // };
  return new Promise<Blob>((resolve, reject) => {
    img.onload = () => {
      // Get the natural width and height of the image
      const imgWidth = img.width;
      const imgHeight = img.height;

      // Get the page size of the PDF (A4 by default)
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      // Calculate the scaling factor to fit the image on the page while maintaining the aspect ratio
      const scaleFactor = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);

      // Calculate the new dimensions to fit within the PDF page size
      const scaledWidth = imgWidth * scaleFactor;
      const scaledHeight = imgHeight * scaleFactor;

      // Add the image to the PDF with the calculated dimensions
      pdf.addImage(`data:image/jpeg;base64,${base64Image}`, 'JPEG', 0, 0, scaledWidth, scaledHeight);

      // Generate PDF Blob and resolve the promise
      const pdfBlob: Blob = pdf.output("blob"); // Generate PDF Blob
      resolve(pdfBlob);
    };

    img.onerror = (err) => {
      // eslint-disable-next-line no-console
      console.error("Error loading image:", err);
      reject(err);  // Reject if there's an error loading the image
    };
  });
};

export const convertPDFToBase64 = async (pdfFile: Blob) => {
  return new Promise<any>((resolve, reject) => {
    // const reader = new FileReader();

    // reader.onerror = (err) => {
    //   // If there's an error, reject the promise
    //   reject(err);
    // };

    // // Start reading the file as a Data URL
    // reader.readAsDataURL(pdfFile);
    try {
      const reader = new FileReader();
      // Create an object URL from the Blob (PDF file)
      const url = URL.createObjectURL(pdfFile);
      reader.onloadend = () => {
        // Once the reader finishes reading, resolve with the base64 string
        const base64String = (reader.result as string).split(',')[1]; // Strip off the data URL prefix
        resolve({
          url,
          base64String
        });
      };
      reader.readAsDataURL(pdfFile);
      // Resolve with the generated URL
    } catch (err) {
      // If there's an error, reject the promise
      reject(err);
    }
  });
};

export const formatMobileNumber = (mobileNumber: string) => {
  return (`(${mobileNumber?.slice(0, 3)}) ${mobileNumber?.slice(
    3,
    6
  )}-${mobileNumber?.slice(6, 10)}`)
}

// export const handleFile = async (file: any) => {
//   const fileType = file.type;
//   // eslint-disable-next-line no-console
//   console.log('File Type', fileType, file)
//   if (fileType === "application/pdf") {
//     // If it's a PDF, convert it to Base64
//     const pdfData: any = await convertPDFToBase64(file);
//     return pdfData;
//   } else if (fileType && fileType.startsWith("image/")) {
//     // If it's an image (JPEG, PNG), convert it to Base64 and then to PDF
//     const reader: any = new FileReader();
//     reader.onloadend = async () => {
//       const imageB64String: any = await convertImageToPDF(reader.result);
//       // eslint-disable-next-line no-console
//       console.log('Image b64 string', imageB64String);
//       const base64Pdf: any = await convertPDFToBase64(imageB64String);
//       // eslint-disable-next-line no-console
//       console.log('Image PDF b64 string', base64Pdf);
//       return base64Pdf;
//     };
//     reader.readAsDataURL(file); // Convert image to Base64
//   } else if (
//     !fileType &&
//     file?.split(",")[0]?.includes("data:image/jpeg;base64")
//   ) {
//     const imageB64String: any = await convertImageToPDF(file?.split(",")[1]);
//     const base64Pdf: any = await convertPDFToBase64(imageB64String);
//     return base64Pdf;
//   }
// };

export const handleFile = async (file: any) => {
  const fileType = file.type;
  console.log('File Type', fileType, file);

  if (fileType === "application/pdf") {
    const pdfData: any = await convertPDFToBase64(file);
    return pdfData;
  } else if (fileType && fileType.startsWith("image/")) {
    return new Promise((resolve, reject) => {
      const reader: FileReader = new FileReader();
      reader.onloadend = async () => {
        try {
          const imageB64String: any = await convertImageToPDF(reader.result);
          console.log('Image b64 string', imageB64String);
          const base64Pdf: any = await convertPDFToBase64(imageB64String);
          console.log('Image PDF b64 string', base64Pdf);
          resolve(base64Pdf); // Resolve with the PDF data
        } catch (error) {
          reject(error); // Reject on error
        }
      };
      reader.readAsDataURL(file); // Convert image to Base64
    });
  } else if (
    !fileType &&
    file?.split(",")[0]?.includes("data:image/jpeg;base64")
  ) {
    const imageB64String: any = await convertImageToPDF(file?.split(",")[1]);
    const base64Pdf: any = await convertPDFToBase64(imageB64String);
    return base64Pdf;
  }

  // Handle unsupported file types
  throw new Error("Unsupported file type");
};
export const dropDownOptionsBinding = async (setRelationshipOptions: any) => {
  const dropOptionsPayload = {
    references: [
      'CALL_TIME',
      'GOVERNMENT_ID_TYPE',
      'RELATIONSHIP_TYPE',
      'EMPLOYER_PAYSCHEDULE',
      'DE_EXCEPTION_REASON',
    ],
    status: 'Y',
  };
  const getDropOptionsRes: any = await GetDropOptions(dropOptionsPayload, true, '02202')
  if (getDropOptionsRes) {
    setRelationshipOptions(
      buildDropdownList(getDropOptionsRes?.relationshipType)
    );
  }
}


export const dynamicRouteDeciderFunction = async (decisionEngineDetails: any) => {

}

export const formatText = (value: any): string => {
  return value && value.trim().length == 0
    ? value.trim().replace(/[^A-Z'.;’ a-z]/gi, "")
    : value.replace(/[^A-Z'.;’ a-z]/gi, "");
};
export function MakeFeatureFlagObjFn(storeValue: any) {
  const tempFeatureFlagObj: any = {};
  for (let i = 0; i < storeValue?.length; i++) {
    tempFeatureFlagObj[storeValue[i]?.paramKeyName] = storeValue[i]?.paramValue;
  }
  console.log('tempFeatureFlagObj', tempFeatureFlagObj);
  return tempFeatureFlagObj;
}

export function getVerificationStatus(response: any): string | null {
  // Define levels based on the question
  const level1 = ['ID', 'RESIDENCE'];
  const level2 = ['ID', 'RESIDENCE', 'EMPLOYMENT'];
  const level3 = ['ID', 'RESIDENCE', 'EMPLOYMENT', 'REFERENCES'];
  const level4 = ['ID', 'RESIDENCE', 'EMPLOYMENT', 'REFERENCES'];

  // Check if verification status is PENDING
  if (response.verificationStatus !== 'PENDING') {
    return null;
  }

  // Check if verificationDocuments array has length
  if (!Array.isArray(response.verificationDocuments) || response.verificationDocuments.length === 0) {
    return null;
  }

  // Get the verification level from the response
  const verifyLevel = response.verifyLevel;

  // Based on the verification level, filter the documents
  let levelDocuments: any = [];
  switch (verifyLevel) {
    case '1':
      levelDocuments = response.verificationDocuments.filter((doc: any) =>
        level1.includes(doc.verifyType) && doc.verificationStatus.toUpperCase() === 'PEND'
      );
      break;
    case '2':
      levelDocuments = response.verificationDocuments.filter((doc: any) =>
        level2.includes(doc.verifyType) && doc.verificationStatus.toUpperCase() === 'PEND'
      );
      break;
    case '3':
      levelDocuments = response.verificationDocuments.filter((doc: any) =>
        level3.includes(doc.verifyType) && doc.verificationStatus.toUpperCase() === 'PEND'
      );
      break;
    case '4':
      levelDocuments = response.verificationDocuments.filter((doc: any) =>
        level4.includes(doc.verifyType) && doc.verificationStatus.toUpperCase() === 'PEND'
      );
      break;
    default:
      return null;
  }

  // If there are documents to process, prioritize them
  if (levelDocuments.length > 0) {
    for (const doc of levelDocuments) {
      if (doc.verifyType === 'ID') {
        return 'identity';
      } else if (doc.verifyType === 'RESIDENCE') {
        return 'residence';
      } else if (doc.verifyType === 'EMPLOYMENT') {
        return 'income';
      } else if (doc.verifyType === 'REFERENCES') {
        return 'reference';
      }
    }
  }

  // If no documents with 'PEND' status found, return null
  return null;
}


export const setDocumentState = async (approvalId: any, setIdentityFrontDoc: any, setResidenceDoc: any, setIncomeDoc: any) => {

  const request: any = {
    identifier: approvalId,
    documentStatus: "signed",
    actionType: "print",
    identifierType: "CUST",
    customerVerificationDocument: "true"
  }

  const getVerificationDocumentsRes: any = getVerificationDocuments(request, true, '02202')

  if (getVerificationDocumentsRes && getVerificationDocumentsRes?.data && getVerificationDocumentsRes?.status == 200 && getVerificationDocumentsRes?.data?.documents?.length > 0) {

    const documents: any = getVerificationDocumentsRes?.data?.documents

    // Helper function to get the most recent document of each type
    const getMostRecentDocument = (docType: string): Document | undefined => {
      // Filter documents by type
      const filteredDocs = documents.filter((doc: any) => doc.documentName === docType);

      // Sort by createdDate in descending order to get the most recent document
      filteredDocs.sort((a: any, b: any) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime());

      // Return the most recent document (or undefined if no documents)
      return filteredDocs.length > 0 ? filteredDocs[0] : undefined;
    };

    // Set the state for each document type
    const identityDoc = getMostRecentDocument("IdentityDocument");
    const residenceDoc = getMostRecentDocument("ResidenceDocument");
    const incomeDoc = getMostRecentDocument("EmploymentDocument");

    // Call the respective setState functions

    setIdentityFrontDoc(identityDoc);
    setResidenceDoc(residenceDoc);
    setIncomeDoc(incomeDoc);
  }

}


export const PublishDOFNotificationFn = async (getApprovalResponse: any, getCustomerRespone: any, salesLeadId: any, sessionStorageValues: any) => {

  const payload = mapCustomerAndApprovalDetailsToPayload(getCustomerRespone, getApprovalResponse, salesLeadId, sessionStorageValues);
  PublishDOFNotification(payload, true, '00429')
  const storeActionItemCreationPayload: any = {
    firstName: payload?.firstName ? payload?.firstName : '',
    lastName: payload?.lastName ? payload?.lastName : '',
    storeNumber: payload?.storeNumber ? payload?.storeNumber : '',
    storeActionCategory: 'CUS',
    storeActionType: 'CVREVPEN',
    customerId: payload?.customerId ? payload?.customerId : '',
    categoryActionId: payload?.customerId ? payload?.customerId : '',
    redirectURL: `/customer/update/${payload?.customerId}/customer`,
    actionItemDescription: `Customer Verification Review is Pending for ${payload?.firstName + ' ' + payload?.lastName}`,
    storeActionItemCreation: true

  }
  StoreActionItemInsertion(storeActionItemCreationPayload, true, '00429')

}
const mapCustomerAndApprovalDetailsToPayload = (customerDetails: any, approvalDetails: any, salesLeadId: any, sessionStorageValues: any): any => {
  // Helper function to return null if the value is empty, null, undefined, or doesn't exist
  const getNullable = (value: any) => {
    return (value === undefined || value === null || value === "" || (typeof value === "object" && Object.keys(value).length === 0)) ? null : value;
  };

  // Map the customer and approval details to the payload format
  const payload: any = {
    customerId: getNullable(customerDetails?.personalInfo?.customerId),
    firstName: getNullable(customerDetails?.personalInfo?.firstName),
    lastName: getNullable(customerDetails?.personalInfo?.lastName),
    emailAddress: getNullable(customerDetails?.personalInfo?.emailAddress),
    dateOfBirth: getNullable(customerDetails?.personalInfo?.dateOfBirthEnc), // Using decrypted DOB
    addresses: (customerDetails?.addresses || [])
      .filter((address: any) => getNullable(address?.active) === "Y") // Filter for active addresses
      .map((address: any) => ({
        addressType: getNullable(address?.addressType),
        addressLine1: getNullable(address?.addressLine1),
        addressLine2: getNullable(address?.addressLine2),
        city: getNullable(address?.city),
        state: getNullable(address?.state),
        postalCode: getNullable(address?.postalCode)
      })) || [],
    phones: (customerDetails?.phones || [])
      .filter((phone: any) => getNullable(phone?.active) === "Y") // Filter for active phones
      .map((phone: any) => ({
        phoneNumber: getNullable(phone?.phoneNumber),
        phoneType: getNullable(phone?.phoneType),
        primary: getNullable(phone?.primary),
        callTimeType: getNullable(phone?.callTimeType),
        active: getNullable(phone?.active)
      })) || [],
    taxId: getNullable(customerDetails?.taxInfo?.taxIdEnc), // Using decrypted Tax ID
    preferredLanguage: getNullable(customerDetails?.personalInfo?.preferredLanguage),
    storeNumber: getNullable(sessionStorageValues?.storeNumber),
    decisionId: getNullable(approvalDetails?.decisionId),
    globalCustomerId: getNullable(approvalDetails?.globalCustomerId),
    approvalDate: getNullable(approvalDetails?.approvalDate),
    approvalStatusCode: getNullable(approvalDetails?.approvalStatusCode),
    approvalStatusDesc: getNullable(approvalDetails?.approvalStatusDesc),
    approvalOriginator: getNullable(approvalDetails?.approvalOriginator),
    approvalReason: getNullable(approvalDetails?.approvalReason),
    approvalResponse: getNullable(approvalDetails?.approvalResponse),
    verifyCode: getNullable(approvalDetails?.verifyCode),
    deScore: getNullable(approvalDetails?.deScore),
    expVerifyStatus: getNullable(String(approvalDetails?.expVerifyStatus)),
    expVerifyReportId: getNullable(approvalDetails?.expVerifyReportId),
    verifyLevel: getNullable(approvalDetails?.verifyLevel),
    verificationStatus: getNullable(approvalDetails?.verificationStatus),
    verificationDocuments: (approvalDetails?.verificationDocuments || [])
      .filter((doc: any) => doc?.verificationStatus === 'PENDREW')
      .map((doc: any) => ({
        verifyType: getNullable(doc?.verifyType),
        descEn: getNullable(doc?.descEn),
        descEs: getNullable(doc?.descEs),
        details: getNullable(doc?.details),
        detailsEs: getNullable(doc?.detailsEs),
        verifyRequired: getNullable(doc?.verifyRequired),
        verified: getNullable(doc?.verified),
        verificationStatus: getNullable(doc?.verificationStatus),
        verificationStatusDesc: getNullable(doc?.verificationStatusDesc),
        override: getNullable(doc?.override),
        overrideDate: getNullable(doc?.overrideDate),
        clientId: getNullable(doc?.clientId),
        createdBy: getNullable(doc?.createdBy),
        createdDate: getNullable(doc?.createdDate),
        lastModifiedBy: getNullable(doc?.lastModifiedBy),
        lastModifiedDate: getNullable(doc?.lastModifiedDate)
      })) || [],
    bavVerifyLevel: getNullable(approvalDetails?.bavVerifyLevel),
    docVerifyLevel: getNullable(approvalDetails?.docVerifyLevel),
    eventType: 1,
    isPublishDOFNotification: true,
    salesLeadId: salesLeadId
  };

  return payload;
};


