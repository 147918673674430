/*eslint-disable */
import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios';

import { ApiUrls, appConfig } from '../config/app-config';
import { Auth } from '@aws-amplify/auth';

async function getJWToken() {
  try {
    const currentSession = await Auth.currentSession();

    return currentSession.getIdToken().getJwtToken();
  } catch (error) {
    throw new Error(`An error occurred: ${error}.`);
  }
}


function generate_uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var uuid = (Math.random() * 16) | 0,
      v = c == 'x' ? uuid : (uuid & 0x3) | 0x8;
    return uuid.toString(16);
  });
}
export async function clientCall(
  endpoint: string,
  { requestType, body, ...customConfig }: any = {},
  apiUrl: any,
  needAccessToken = true
) {
  try {
    const encodeUserId = window.sessionStorage.getItem('encodeUserId')
    const userId: any = encodeUserId ? encodeUserId : ''
    const headers: any = {
      'Content-Type': 'application/json; charset=UTF-8',
      storeNumber: window.sessionStorage.getItem('storeNumber') || '00000',
      clientId: 'DOF',
      userId: userId ? userId : '3913006',
      correlationId: generate_uuidv4()
    };

    const requestConfig: any = {
      method: requestType,
      ...customConfig,
      headers: {
        ...headers,
        ...customConfig.headers,
      },
    };

    if (customConfig.payload) {
      requestConfig.data = customConfig.payload;
    }

    if (body) {
      requestConfig.data = JSON.stringify(body);
    }
    console.log('requestConfig', requestConfig);
    return await axios(`${apiUrl}/${endpoint}`, requestConfig).then(
      (response) => response.data
    );
  } catch (e: any) {
    return e.response;
  }
}

export async function client(
  endpoint: string,
  { requestType, body, ...customConfig }: any = {},
  apiUrl: any,
  needAccessToken = false,
) {
  try {
    const encodeUserId = window.sessionStorage.getItem('encodeUserId')
    const userId: any = encodeUserId ? encodeUserId : ''
    const headers: any = {
      'Content-Type': 'application/json',
      storeNumber: window.sessionStorage.getItem('storeNumber') || '02202',
      clientId: 'DOF',
      userId: userId ? userId : '3913006',
      correlationId: generate_uuidv4()
    };

    const requestConfig: any = {
      method: requestType,
      ...customConfig,
      headers: {
        ...headers,
        ...customConfig.headers,
      },
    };

    if (customConfig.payload) {
      requestConfig.data = customConfig.payload;
    }

    if (body) {
      requestConfig.data = JSON.stringify(body);
    }
    console.log('requestConfig', requestConfig);
    return await axios(`${apiUrl}/${endpoint}`, requestConfig).then(
      (response) => response
    );
  } catch (e: any) {
    return e.response;
  }
}



export const getCancelTokenSource = (): CancelTokenSource =>
  axios.CancelToken.source();
