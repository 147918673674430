/* eslint-disable */
import { CircularProgress, Grid, makeStyles } from "@rentacenter/racstrap";

const useStyles = makeStyles(() => ({

    backgroundBlur: {
        height: "100%",
        width: "100%",
        zIndex: 1500,//Based on the Menu's Zindex (1030) this value is provided
        position: "fixed",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(255,255,255,0.5)"
    }

}));

export default function ScreenLoader():JSX.Element {
    const styles = useStyles();
    return (
        <Grid
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            aria-hidden="true"
            className={styles.backgroundBlur}
        >
            <CircularProgress />
        </Grid>
    );
}
