/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable sonarjs/no-redundant-boolean */
/* eslint-disable sonarjs/cognitive-complexity */

import { Grid, RACButton, Typography } from "@rentacenter/racstrap";
import React, { useEffect, useState, useRef, useContext } from "react";
import { sendIntellicheckVerificationLink } from "../../../api/user";
import { useHistory, useParams } from "react-router-dom";
import ScreenLoader from "../Common/ScreenLoader";
import { VerificationStyles } from "../../../styles/verificationStyles";
import { DigitalOrderFormContext } from "../Context/DigitialOrderFormContext";
import {
  getCustomerDetails,
  getIntellicheckDetails,
} from "../Common/GetCustomerDetails";
import {
  Address,
  CustomerInfo,
  IntellicheckCustomerDetails,
} from "../../../interfaces/CustomerVerificationModel/DigitalOrderFormModel";
import {
  BUSINESS_UNIT,
  EMPTY_STRING,
  INTELLICHECK_DETAILS_TEMPLATE,
  SOMETHING_WENT_WRONG,
  UNITED_STATES,
} from "../../../constants/constants";
import {
  AddressType,
  BooleanRepresentation,
  GovernmentIdType,
  IntellicheckGovtIDType,
  Status,
} from "../../../constants/enums";
import {
  getIdDocumentType,
  updateCustomerApproval,
} from "../Common/HelperFunctions";
import SomethingWentWrongPopUp from "../Common/SomethingWentWrong";
import MessagePopup from "../Common/MessagePopup";

export const IdentityTab = () => {
  const classes: any = VerificationStyles();
  const { customerDetails, setCustomerDetails, setIntellicheckVerfDetails, setShowIdentityDetails } =
    useContext(DigitalOrderFormContext);
  // PS_07: useState for iframe URL
  const [iframeUrl, setIframeUrl] = useState<string | null>(null);
  const [verificationComplete, setVerificationComplete] =
    useState<boolean>(false);
  const iframeRef = useRef<any>("");
  const [loader, setLoader] = useState<boolean>(false);
  const history = useHistory();

  const queryParams = new URLSearchParams(window.location.search);
  const paramValue = queryParams.get("status");
  const { customerId, approvalId } = useParams<any>();
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  //handling the style
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  // Conditional rendering based on screen width
  const isMobile = screenWidth <= 480;
  const isTablet = screenWidth > 480 && screenWidth <= 1037;
  const isDesktop = screenWidth > 1037;

  useEffect(() => {
    setLoader(true);
    console.log('Loader', loader);
    fetchAndSendIntellicheckLink(customerId);
  }, [paramValue]);

  useEffect(() => {
    // Call sendHeightToParent initially to send the height on mount
    sendHeightToParent();
    // Add an event listener for window resizing
    const handleResize = () => {
      sendHeightToParent();
    };
    window.addEventListener("resize", handleResize);
  }, [loader]);

  const sendHeightToParent = () => {
    if (!loader) {
      const iframeHeight = document.body.scrollHeight; // Get the current height of the iframe content
      const iframeWidth = document.body.scrollWidth;
      console.log('Inside the Iframe Message send', iframeHeight)
      window.parent.postMessage(
        {
          message: "racpad-styling",
          minHeight: iframeHeight < 640 ? 640 : iframeHeight,
          currentHeight: iframeHeight,
          iframeWidth: iframeWidth,
          verificationProcessCompleted: false
        },
        "*"
      );
    }
  };

  console.log("Loader", loader);

  // Main function to fetch customer details and send Intellicheck link
  const fetchAndSendIntellicheckLink = async (customerId: string) => {
    try {
      if (!customerDetails?.personalInfo?.customerId) {
        const customerData: CustomerInfo = await getCustomerDetails(
          customerId,
          setCustomerDetails
        );
        sendIntellicheckLink(customerData);
      } else {
        sendIntellicheckLink(customerDetails);
      }
    } catch (error: any) {
      console.log("Error in Fetch and Send Intellicheck Link service", error);
      setLoader(false);
      setErrorMessage(SOMETHING_WENT_WRONG);
      setError(true);
    }
  };

  // Function to create the payload for sending Intellicheck link
  const createPayload = (customerDetails: CustomerInfo) => {
    const primaryAddress = getPrimaryAddress(customerDetails);
    // const idDocumentType = getIdDocumentType(customerDetails);
    const featureFlagDetails: any = window.sessionStorage.getItem('featureFlagDetails')
    console.log('featureflag details', featureFlagDetails);
    let documentSides: any = '';
    if(featureFlagDetails){
      documentSides = JSON.parse(featureFlagDetails);
      documentSides = JSON.parse(documentSides?.find((ele: any)=> ele.paramKeyName == 'IntellicheckDocumentSides')?.paramValue?.slice(1, -1))?.values;
      console.log('document sides', documentSides);
    } else {
      documentSides = ['front']
    }

    return {
      redirectUrl: `${origin}/${customerId}/${approvalId}?status=completed`,
      globalCustomerId: `cust-${approvalId}`,
      businessUnit: BUSINESS_UNIT,
      dateOfBirth: customerDetails.personalInfo.dateOfBirthDec,
      firstName: customerDetails.personalInfo.firstName,
      lastName: customerDetails.personalInfo.lastName,
      addressLine1: primaryAddress?.addressLine1,
      city: primaryAddress?.city,
      provinceCode: primaryAddress?.state,
      postalCode: primaryAddress?.postalCode,
      countryCode: UNITED_STATES,
      requestedDocumentSides: documentSides,
    };
  };

  // Function to fetch the primary address from the customer details
  const getPrimaryAddress = (customerDetails: CustomerInfo) => {
    return customerDetails.addresses.find(
      (address: Address) =>
        address.addressType === AddressType.Primary &&
        address.active === BooleanRepresentation.Y
    );
  };

  // Function to send the Intellicheck link with the payload
  const sendIntellicheckLink = async (customerDetails: CustomerInfo) => {
    const payload = createPayload(customerDetails);
    console.log("Inside the SendIntellicheck Details", payload);
    if (paramValue && paramValue === Status.completed) {
      console.log("Inside Completed Param value", payload);
      setVerificationComplete(true);
      setIframeUrl("");
      setLoader(false);
      return;
    }

    try {
      const response = await sendIntellicheckVerificationLink(payload);
      console.log("Response from SendIntellicheck Service", response);
      if (response.status == 200) {
        setLoader(false);
        if (response.data?.value?.code == 500) {
          console.log("Sucesses Response from Intellicheck", response?.data);
          setError(true);
          setErrorMessage(
            "Intellicheck verification failed. Please try again later."
          );
        } else {
          console.log("Sucesses Response from Intellicheck", response?.data);
          setIframeUrl(response?.data?.value?.capture_url);
        }
      } else {
        console.log("Error In send Intellicheck");
        setError(true);
        setErrorMessage(SOMETHING_WENT_WRONG);
        setLoader(false);
      }
    } catch (error: any) {
      console.log("Error in SendIntellicheck Service Call", error);
      setError(true);
      setErrorMessage(SOMETHING_WENT_WRONG);
      setLoader(false);
    } finally {
      setLoader(false);
    }
  };

  // PS_28: Handle iframe load and redirect detection
  const handleIframeLoad = () => {
    // eslint-disable-next-line no-console
    console.log(
      "Iframe Reference details",
      iframeRef,
      iframeRef.current?.contentWindow?.location.href?.includes(
        window.location.origin
      )
    );

    if (
      iframeRef.current?.contentWindow?.location.href?.includes(
        window.location.origin
      )
    ) {
      // eslint-disable-next-line no-console
      console.log(
        "Inside the completed Iframe",
        iframeRef.current?.contentWindow?.location.href
      );
      handleVerificationComplete();
    }
  };

  // PS_29: Handle verification complete
  const handleVerificationComplete = async () => {
    try {
      setLoader(true);
      // eslint-disable-next-line no-console
      console.log("Inside the handleVerificationComplete");
      setIframeUrl(null);
      setVerificationComplete(true);
      history.push(`/identity/${customerId}/${approvalId}?status=completed`);
      const fetchIntellicheckVerificationDetails:
        | IntellicheckCustomerDetails
        | string = await getIntellicheckDetails(
        approvalId,
        setIntellicheckVerfDetails,
        customerId
      );
      if (fetchIntellicheckVerificationDetails !== "incomplete")
        setIntellicheckVerfDetails(fetchIntellicheckVerificationDetails);
      setLoader(false);
    } catch (error: any) {
      console.log("Error in Fetch Intellicheck Details Service", error);
      setLoader(false);
      setIntellicheckVerfDetails(INTELLICHECK_DETAILS_TEMPLATE);
    }
  };

  // PS_31: Handle next button click
  const handleNext = () => {
    setShowIdentityDetails(true)
    history.push(`/residence/${customerId}/${approvalId}`);
    updateCustomerApproval("ID", approvalId, customerId);
  };

  // Function to update screen width on window resize
  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  // Set up an event listener for window resize
  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const checkVerificationCompleted = async () => {
    setLoader(true);
    try {
      const fetchIntellicheckVerificationDetails:
        | IntellicheckCustomerDetails
        | string = await getIntellicheckDetails(
        approvalId,
        setIntellicheckVerfDetails,
        customerId
      );
      if (fetchIntellicheckVerificationDetails == "incomplete") {
        setError(true);
        setErrorMessage(
          "Please complete the verification process to proceed with the next steps."
        );
      } else {
        setIntellicheckVerfDetails(fetchIntellicheckVerificationDetails);
        history.push(`/identity/${customerId}/${approvalId}?status=completed`);
      }
    } catch (e: any) {
      console.log("Error in checkVerificationCompleted function", e);
      setLoader(false);
    }
    setLoader(false);
  };

  return (
    <Grid>
      {error ? (
        <MessagePopup
          value={{
            message: errorMessage,
            setFunction: setError,
            cleanupFunction: setErrorMessage,
          }}
        />
      ) : null}
      {loader ? <ScreenLoader /> : null}
      <Grid
        className={
          isMobile
            ? `${classes.mobileFrame}`
            : isTablet
            ? `${classes.tabFrame}`
            : `${classes.desktopFrame}`
        }
        style={{ width: "100%", height: "80vh" }}
      >
        {iframeUrl && (
          <Grid className={`${classes.iframeContainer}`}>
            <iframe
              src={iframeUrl}
              onLoad={handleIframeLoad}
              ref={iframeRef}
              title="Identity Verification"
              className={
                isMobile
                  ? `${classes.iframeMobile}`
                  : isTablet
                  ? `${classes.iframeTab}`
                  : `${classes.iframeDesktop}`
              }
              allow={"camera"}
              frameBorder={"0"}
              id="iframe"
            />
            {isDesktop && (
              <RACButton
                variant="contained"
                color="primary"
                onClick={() => checkVerificationCompleted()}
                className={`${classes.mb15px} ${classes.mt20px} ${classes.font16px} ${classes.primaryButtonColor} ${classes.w15p}`}
              >
                Continue
              </RACButton>
            )}
          </Grid>
        )}
        {verificationComplete && (
          <Grid>
            <Typography
              className={`${classes.tittleColor} ${classes.boldFont}`}
            >
              Government ID Submitted
            </Typography>
            <Grid
              className={
                isMobile
                  ? `${classes.p16px} ${classes.textAlignLeft} ${classes.pt0px}`
                  : isTablet
                  ? `${classes.tabGridStyle}`
                  : `${classes.desktopGridStyle}`
              }
            >
              <Typography
                className={`${classes.regularFont} ${classes.blackFont} ${classes.mt20px}`}
              >
                Your goverenment identity validation is complete. Please proceed
                to the next step to continue your verification.
              </Typography>
              <RACButton
                color="primary"
                variant="contained"
                className={
                  isMobile
                    ? `${classes.primaryButtonColor} ${classes.width100p} ${classes.mt20px}`
                    : isTablet
                    ? `${classes.primaryButtonColor} ${classes.tabButtonSize}`
                    : `${classes.primaryButtonColor} ${classes.desktopButtonSize}`
                }
                onClick={() => {
                  handleNext();
                }}
              >
                Next
              </RACButton>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
