/* eslint-disable prettier/prettier */

export enum BooleanRepresentation {
  Yes = "Yes",     // 'Yes'
  No = "No",       // 'No'
  Y = "Y",         // 'Y'
  N = "N",         // 'N'
  True = "true",   // 'true'
  False = "false", // 'false'
  One = "1",       // '1'
  Zero = "0",      // '0'
  Primary = 'P',   // 'P'
  Secondary = 'S'  // 'S'
}

// Enum for government ID types
export enum GovernmentIdType {
DL = 'DL',       // Driver's License
UDL = 'UDL',     // US Driver's License
PASS = 'PASS',   // Passport
UPASS = 'UPASS', // US Passport
}

// Enum for corresponding mapped Intellicheck values.
export enum IntellicheckGovtIDType {
na_dl = 'na_dl',       // For DL or UDL
passport = 'passport', // For PASS or UPASS
other = 'other',       // For other types
}

export enum Status {
pending = 'pending',
pendingReview = 'pending-review',
completed = 'completed',
failed = 'failed'
}

export enum AddressType {
Primary = 'PRIM', 
Mailing = 'MAIL', 
Delivery = 'DELIV', 
Billing = 'B'
}